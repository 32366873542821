<template>
  <core-view class="flex-column" fill-height>
    <template>
    <div class="center" v-if="!loading">
      <form>
        <div v-show="formAtual === 1">
          <div class="linha">
            <new-text-field label="Operação"
            v-model="formulario.operacao" itemLabel="opedescricao"  readonly
            hide-details>
            </new-text-field>
          </div>
          <div class="linha">
            <new-text-field label="Frota (Veículo/Maquinário)"
            v-model="formulario.frota"  readonly hide-details>
            </new-text-field>
          </div>
          <div class="linha">
            <new-text-field label="Operador" v-model="formulario.operadora" readonly
            hide-details>
            </new-text-field>
          </div>
          <div class="linha-justificada">
            <new-text-field :label="formulario.labelodometro" class="pa-0"
             v-model="formulario.odometro"  readonly
            hide-details>
            </new-text-field>
            <new-text-field label="Qtde (LTs)"  readonly
            v-model="formulario.quantidade" hide-details>
            </new-text-field>
          </div>
          <div class="linha">
            <new-text-field label="Origem Abastecimento"
            v-model="formulario.origem"
            itemLabel="lmelabel" height=40  readonly hide-details>
            </new-text-field>
          </div>
          <div class="linha">
            <new-text-field label="Produto" readonly v-model="formulario.produto"
            hide-details
            ></new-text-field>
          </div>
          <div class="linha">
            <new-text-field label="Operador" readonly  v-model="formulario.operadorb"
            hide-details>
            </new-text-field>
          </div>
        </div>
        <div v-show="formAtual === 2">
          <div class="linha">
            <new-text-field label="Local (Propriedade)"
            hide-details
            icon="mdi-chevron-down"
             readonly
            v-model="formulario.propriedade">
            </new-text-field>
          </div>
          <div class="linha">
            <new-text-field label="Atividade"
            hide-details
            icon="mdi-chevron-down"
            readonly
            v-model="formulario.atividade">
            </new-text-field>
          </div>
          <div class="linha">
            <v-textarea maxlength=200 outlined counter v-model='formulario.descricao'
            label="Descrição"  readonly>
            </v-textarea>
          </div>
        </div>
        <div class="divinferior">
          <v-btn height=45 color="green botaoinferior" @click="$_trocaFormulario()"
          v-if="formAtual === 1">
            Próximo
          </v-btn>
          <div v-else>
          <v-btn height=45 color="grey botaoinferior botaomargim" @click="$_trocaFormulario()">
            Voltar
          </v-btn>
          </div>
        </div>
      </form>
    </div>
 </template>

</core-view>
</template>

<script>
// import errorHandler from '@/utils/error-handler';

import {
  ROUTE_FROTA, ROUTE_OPERADOR, ROUTE_ORIGEM, ROUTE_PROPRIEDADE, ROUTE_ATIVIDADE,
} from '@/constants/ROUTES';

import { ABASTECIMENTO_STORAGE } from '@/constants/STORAGE';

import CoreView from '@/components/core/view/Index.vue';

import NewTextField from '@/components/core/new-text-field/Index.vue';

// import moment from 'moment-timezone';

import db from '@/plugins/dexie';
import moment from 'moment';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

export default {
  name: 'ViewHome',

  components: {
    CoreView,
    NewTextField,
  },

  data() {
    return {
      rotaFiltros: [
        ROUTE_FROTA,
        ROUTE_OPERADOR,
        ROUTE_ORIGEM,
        ROUTE_ATIVIDADE,
        ROUTE_PROPRIEDADE,
      ],
      chat: [],
      formatado: [],
      wsConnection: null,
      websocket: {
        connectionId: null,
        primeiraconexao: true,
        conectado: false,
      },
      online: false,

      formAtual: 1,

      formulario: {
        operacao: '',
        data: moment().format('YYYY-MM-DD'),
        odometro: '',
        quantidade: '',
        frota: '',
        hora: '',
        tipo: '',
        atividade: '',
        origem: '',
        produto: '',
        operadora: '',
        operadorb: '',
        propriedade: '',
        descricao: '',
        labelodometro: '',
      },

      loading: true,
    };
  },

  methods: {

    $_UpperCase() {
      console.log(this.formulario.frota);
      // this.formulario.frota = this.formulario.frota.toUpperCase();
    },

    $_formataData(date, mascara) {
      return moment.tz(date, 'America/Sao_Paulo').local().format(mascara);
    },

    $_setupCamposReadOnly(idabastecimento) {
      const tabela = db.abastecimento.where({ id: idabastecimento }).first();
      tabela.then((item) => {
        this.formulario.frota = item.abafrota;
        // this.formulario.tipo = getSessionStorage(ABASTECIMENTO_STORAGE.tipo, '');
        this.formulario.operacao = item.abaoperacao;
        this.formulario.origem = item.abaorigem;
        this.formulario.operadora = item.abaoperadorveiculo;
        this.formulario.operadorb = item.abaoperador;
        this.formulario.atividade = item.abaatividade;
        this.formulario.propriedade = item.abalocal;
        this.formulario.produto = item.abaproduto;
        this.formulario.odometro = item.abaodometro;
        this.formulario.quantidade = item.abaquantidade;
        this.formulario.descricao = item.abadescricao;
        this.formulario.labelodometro = item.abatipoodometro;
        this.loading = false;
      });
    },

    async $_trocaFormulario() {
      this.formAtual = (this.formAtual === 2) ? 1 : 2;
      this.icone = (this.formAtual === 2) ? 'mdi-arrow-left-thick' : 'mdi-arrow-right-thick';
    },
  },

  beforeCreate() {
    const idabastecimento = getSessionStorage(ABASTECIMENTO_STORAGE.idabastecimento);
    this.idabastecimento = idabastecimento;
  },

  created() {
    this.$_setupCamposReadOnly(Number(this.idabastecimento));
  },
};
</script>

<style scoped>
  .v-sheet--offset {
    top: 5px;
    position: relative;
  }

  .center {
    width: 100%;
    text-align: center;
  }

  .linha {
    display: flex;
  }

  .linha-justificada {
    display: flex;
    justify-content: space-around;
  }

  .divinferior {
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 5px;
    right: 0%;
  }

  .botaoinferior {
    color: white;
  }

  @media screen and (min-width:600px) {
    .tablet {
      display: flex;
    }
  }

</style>
