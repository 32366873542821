import apipermissaoweb from '@/services/api-recebimentoweb';

export default {
  namespaced: true,

  state: {
    list: [],
  },

  getters: {
    list: (state) => state.list,
  },

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
  actions: {

    async findByModulo(_, { idUsuario, idModulo }) {
      const { data } = await apipermissaoweb.get(`permissao/${idUsuario}/${idModulo}`);
      return data;
    },

    async findAll(_, { idUsuario }) {
      const { data } = await apipermissaoweb.get(`permissao/${idUsuario}`);
      return data;
    },
  },

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
  mutations: {
    SET_LIST(state, list) {
      state.list = list ?? [];
    },
  },
};
