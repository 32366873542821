<template>
  <layout-default-filters @open="$_FilterReset" @apply="$_applyQueryString">
    <v-form ref="form">
      <div class="linha">
        <btn-auto-complete label="Placa" icon="mdi-chevron-down"
          v-model="filters.placa" :dados="autocomplete.veiculo"
          itemLabel="veiplaca">
        </btn-auto-complete>
      </div>
      <div class="linha">
        <btn-auto-complete label="Tipo Maquinário" icon="mdi-chevron-down"
          v-model="filters.tipo" :dados="autocomplete.veiculo"
          itemLabel="veitipomaquinario">
        </btn-auto-complete>
      </div>
      <div class="linha">
        <v-btn rounded color="primary" width="175" @click="$_limpar">
          LIMPAR
        </v-btn>
      </div>
    </v-form>
  </layout-default-filters>
</template>

<script>
import { GENERIC_QUERIES, FROTA_QUERIES } from '@/constants/QUERY_FILTERS';

import { getQueryValue, setQueryValue } from '@/services/query-string';

import LayoutDefaultFilters from '@/layouts/default/Filters.vue';

// import NewTextField from '@/components/core/new-text-field/Index.vue';
import BtnAutoComplete from '@/components/core/btn-auto-complete/Index.vue';

import db from '@/plugins/dexie';

export default {
  name: 'ViewsHomeFilters',

  components: {
    LayoutDefaultFilters,
    BtnAutoComplete,
  },

  data() {
    return {
      isValid: false,

      filters: {
        placa: '',
        tipo: '',
      },

      autocomplete: {
        veiculo: [],
      },
    };
  },

  methods: {
    $_applyQueryString() {
      setQueryValue(GENERIC_QUERIES.page, 1);
      setQueryValue(FROTA_QUERIES.tipo, this.filters.tipo.veitipomaquinario);
      setQueryValue(FROTA_QUERIES.placa, this.filters.placa.veiplaca);
    },

    $_FilterReset() {
      this.filters.tipo = getQueryValue(
        FROTA_QUERIES.tipo, this.filters.tipo,
      );
      this.filters.placa = getQueryValue(
        FROTA_QUERIES.placa,
        this.filters.placa,
      );
    },

    async $_setupAutocomplete() {
      this.autocomplete.veiculo = await db.veiculo.toArray();
    },

    $_limpar() {
      this.filters.tipo = '';
      this.filters.placa = '';
      setQueryValue(FROTA_QUERIES.tipo, 'null');
      setQueryValue(FROTA_QUERIES.placa, 'null');
    },
  },

  created() {
    this.$_setupAutocomplete();
  },

  watch: {
    filters: {
      deep: true,
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      },
    },

    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.filters.tipo = getQueryValue(
          FROTA_QUERIES.tipo,
          this.filters.tipo,
        );

        this.filters.placa = getQueryValue(
          FROTA_QUERIES.placa,
          this.filters.placa,
        );
      },
    },
  },
};
</script>
