import SnackbarEvent from '@/events/SnackbarEvent';

export default function errorHandler(err) {
  let message = err.message ?? err;

  if (err.response?.data?.error?.code) {
    message = this.$t(`loopbackErrorCodes.${err.response.data.error.code}`);
  }

  document.dispatchEvent(new SnackbarEvent({ message }));
}
