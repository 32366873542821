import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
      theme: {
        themes: {
          light: {
            primary: process.env.VUE_APP_THEME_COLOR_PRIMARY,
            secondary: process.env.VUE_APP_THEME_COLOR_SECONDARY,
            accent: '#82B1FF',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107',
          },
        },
      },
      breakpoint: {
        mobileBreakpoint: 'sm',
      },
});


