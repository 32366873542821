export default {
  async setItem(key, value) {
    const json = JSON.stringify(value);
    return sessionStorage.setItem(key, json);
  },

  async getItem(key, defaultValue) {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : (defaultValue ?? null);
  },

  async removeItem(key) {
    return sessionStorage.removeItem(key);
  },
};
