import apiaberturaloteweb from '@/services/api-recebimentoweb';

let findTimeout = null;

export default {
  namespaced: true,

  state: {
    list: [],
  },

  getters: {
    list: (state) => state.list,
  },

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
  actions: {
    async find(_, {
      where, ...params
    } = {}) {
      const [{ data }, { data: { count: total } }] = await Promise.all([
        apiaberturaloteweb.get('aberturalote', { params: { ...where, ...params } }),
        apiaberturaloteweb.get('aberturalote/count', { params: where }),
      ]);

      return { data, total };
    },

    // CONSULTA COM CONTROLE DE CACHE
    async findWithCache({ commit, state }, { force, ttl } = {}) {
      if (!force && state.list?.length > 0) {
        return;
      }

      clearTimeout(findTimeout);

      const { data } = await apiaberturaloteweb.get('aberturalote');

      commit('SET_LIST', data);

      if (ttl === Infinity) return;

      findTimeout = setTimeout(() => {
        commit('SET_LIST', null);
      }, ttl ?? 1000 * 60); // TTL -> Time To Live
    },

    // CONSULTA SEM CACHE
    async findById(_, { id }) {
      const { data } = await apiaberturaloteweb.get(`aberturalote/${id}`);
      return data;
    },

    async findByNRO(_, { id }) {
      const { data } = await apiaberturaloteweb.get(`aberturalote/nro/${id}`);
      return data;
    },
  },

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
  mutations: {
    SET_LIST(state, list) {
      state.list = list ?? [];
    },
  },
};
