<!-- eslint-disable vue/valid-v-slot -->
<template>
  <core-view>
    <!-- Sem itens -->
    <!-- <p class="text-center">
      Nenhum item foi encontrado.
    </p> -->
    <core-data-pagination
      ref="dataPagination"
      :loading="loading"
      :load-handler="$_load"
      :headers="headers"
      :options.sync="options"
    >
      <template #card-skeleton-loader="{ attrs, on }">
        <app-card-propriedade-skeleton v-bind="attrs" v-on="on" actions />
      </template>

      <template #card="{ item, attrs, on }">
        <app-card-propriedade actions
          v-bind="attrs"
          v-on="on"
          :value="item"
          @click="$_onClickItem($event, item)" >
        </app-card-propriedade>
      </template>
      <template #data-table="{ attrs, on }">
        <core-data-table v-bind="attrs" v-on="on">
          <template #item.actions="{ item }">
            <core-btn-icon
              color="primary"
              label="Editar"
              @click="$_onClickItem($event, item)">
              mdi-pencil-outline
            </core-btn-icon>
          </template>
        </core-data-table>
      </template>
    </core-data-pagination>
  </core-view>
</template>

<script>

// import errorHandler from '@/utils/error-handler';

import { ROUTE_FROTA } from '@/constants/ROUTES';

import CoreView from '@/components/core/view/Index.vue';
import CoreDataPagination from '@/components/core/new-data-pagination/Index.vue';
import CoreDataTable from '@/components/core/data-table';

import errorHandler from '@/utils/error-handler';

import db from '@/plugins/dexie';
import AppCardPropriedade from '../../components/app/card/propriedade/Index.vue';
import AppCardPropriedadeSkeleton from '../../components/app/card/propriedade/Skeleton.vue';

export default {
  name: 'ViewPropriedade',

  components: {
    CoreView,
    CoreDataPagination,
    CoreDataTable,
    AppCardPropriedadeSkeleton,
    AppCardPropriedade,
  },

  data() {
    const headers = [
      {
        text: 'idproduto',
        width: 32 + (36 * 3),
        align: 'center',
        value: 'moeidproduto',
      },
    ];
    return {
      headers,
      rotaFiltros: [
        ROUTE_FROTA,
      ],
      chat: [],
      formatado: [],
      online: false,

      loading: true,

      options: {
        // page: getSessionStorage(ESTOQUE_FROTA.page, 1),
        itemsPerPage: 10,
        sortBy: ['moeidcategoria'],
        sortDesc: [true],
      },
    };
  },

  methods: {
    async $_load({ orderBy, limit, offset }) {
      this.loading = true;

      try {
        const where = {};

        if (this.filters.search) {
          where['moeidcategoria:like'] = true;
        }

        // if (this.filters.createdFrom) {
        //   where['moedataestoque:gte'] = new Date(this.filters.createdFrom).toJSON();
        // }

        // if (this.filters.createdBefore) {
        //   where['moedataestoque:lte'] = new Date(`${this.filters.createdBefore}
        // 23:59:00`).toJSON();
        // }

        // sessionStorage.setItem(ESTOQUE_STORAGE.sort, orderBy);
        // sessionStorage.setItem(ESTOQUE_STORAGE.page, this.options.page);
        // sessionStorage.setItem(ESTOQUE_STORAGE.search,

        // sessionStorage.setItem(ESTOQUE_STORAGE.createFrom, this.filters.createdFrom);
        // sessionStorage.setItem(ESTOQUE_STORAGE.createBefore, this.filters.createdBefore);

        return await this.$_findEstoque({
          orderBy, limit, offset, where,
        });
      } catch (err) {
        errorHandler.call(this, err);
        return { data: [], total: 0 };
      } finally {
        this.loading = false;
      }
    },

    $_onClickItem(mouseEvent, item) {
      this.$router.replace({
        mouseEvent,
        // name: ROUTE_PRODUCAO_ID.name,
        params: { ESTOQUEId: item.id },
      });
    },

    getSessionStorage(key, defaultValue) {
      return sessionStorage.getItem(key) ?? defaultValue;
    },

    async $_findDb(campo) {
      if (campo === 'veiculo') {
        this.autocomplete.frota = await db.veiculo.toArray();
        this.loading = false;
      }
      return null;
    },
  },
};
</script>

<style scoped>
  .v-sheet--offset {
    top: 5px;
    position: relative;
  }

  .center {
    width: 100%;
    text-align: center;
  }

  .linha {
    display: flex;
  }

  @media screen and (min-width:600px) {
    .tablet {
      display: flex;
    }
  }

</style>
