class ToggleFiltersEvent extends CustomEvent {
  constructor() {
    super(ToggleFiltersEvent.EVENT);
  }

  static emit() {
    document.dispatchEvent(new ToggleFiltersEvent());
  }

  static on(handler) {
    document.addEventListener(ToggleFiltersEvent.EVENT, handler);
  }

  static off(handler) {
    document.removeEventListener(ToggleFiltersEvent.EVENT, handler);
  }
}

ToggleFiltersEvent.EVENT = 'app:filters';

export default ToggleFiltersEvent;
