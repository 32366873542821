var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":""}},[(!_vm.near)?_c('br'):_vm._e(),_c('v-spacer'),(!_vm.$_isMobile)?_c('core-btn-toggle',{attrs:{"active-icon":"mdi-table","inactive-icon":"mdi-card-text-outline"},model:{value:(_vm.showAsCard),callback:function ($$v) {_vm.showAsCard=$$v},expression:"showAsCard"}}):_vm._e()],1),(_vm.$_isMobile || _vm.showAsCard)?[_c('core-infinite-scroll',{attrs:{"items":_vm.data,"loading":_vm.loading,"options":_vm.$_options,"server-items-length":_vm.total},on:{"update:options":function($event){_vm.$_options=$event}},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_vm._t("card",null,{"item":item,"attrs":{
              class: {
                'mb-3': true,
                'on-hover': hover,
              },
              elevation: hover ? 12 : 2,
            }})]}}],null,true)})]}},{key:"loading",fn:function(){return [_vm._t("card-skeleton-loader",null,{"attrs":{ key: 1, class: 'mb-3' }})]},proxy:true}],null,true)})]:_vm._t("data-table",null,{"attrs":{
      mustSort: true,
      headers: _vm.headers,
      items: _vm.data,
      options: _vm.$_options,
      serverItemsLength: _vm.total,
      loading: _vm.loading,
      class: 'elevation-2 rounded-lg',
    },"on":{
      'update:options': ($event) => _vm.$_options = $event,
    }})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }