<template>
  <app-card-abastecimento-skeleton v-bind="$attrs" v-on="$listeners" :actions="actions"
  @click="$_emitter">
    <template #contrato >

    <!-- <div class="cardabastecimento">
      <div v-if="value.id" class="idabastecimento"> ID </div>
      <div v-if="value.abadata">
        Data
        Hora
      </div>
      <div v-if="value.abafrota"> Frota </div>
    </div> -->

    <div class="cardabastecimento">
      <div v-if="value.id" class="idabastecimento"> {{ value.id }} </div>
      <div v-if="value.abadata">
        {{$_formataData(value.abadata,'DD/MM/YYYY')}}
        {{$_formataData(value.abadata,'HH:mm')}}
      </div>
      <div v-if="value.abafrota"> - {{ value.abafrota}} </div>
    </div>

     </template>
     <template #detalhe1>
      <div v-if="value.abadescricao">{{value.abadescricao}}</div>
     </template>
  </app-card-abastecimento-skeleton>
</template>

<script>
import moment from 'moment-timezone';
import AppCardAbastecimentoSkeleton from './Skeleton.vue';

export default {
  name: 'AppCardAbastecimento',

  components: {
    AppCardAbastecimentoSkeleton,
  },

  methods: {
    // FormataData(date) {
    //   // return moment(date, 'YYYY-MM-DD').format('DD/MM-HH:MM');
    //   return moment.tz(date, 'America/Sao_Paulo').local().format('DD/MM-HH:mm');
    // },

    $_formataData(date, mascara) {
      return moment.tz(date, 'America/Sao_Paulo').local().format(mascara);
    },

    $_temDescricao(value) {
      // VUE não suporta optional chaining direto no template.
      if (value.produto?.prodescricao) {
        return true;
      }
      return false;
    },

    $_emitter() {
      this.$emit('innerClick', this.value.id);
    },
  },

  props: {
    value: Object,
    actions: Boolean,
  },
};
</script>
/<style scoped>
#principal{
  display: block;
}

.flex-container {
  display: flex;
}

.flex-container > * {
  margin-right: 3px;
}

.idabastecimento {
  margin-right: 10px;
}

.cardabastecimento {
  display: flex;
}
</style>
