<template>
  <app-card-propriedade-skeleton v-bind="$attrs" v-on="$listeners" :actions="actions">
    <template #contrato >

      {{ value.placa }}
       -
     {{ value.frota }}

     </template>
    <template #detalhe1>
      <div v-if="value.marca">{{value.marca }}</div>
    </template>

    <template>
      <div v-if="value.cidade">{{value.cidade}}</div>
      <div v-if="value.cidade"> {{value.cidade}} </div>
      <div v-if="value.codigo">{{value.codigo}}</div>
    </template>

    <slot />
  </app-card-propriedade-skeleton>
</template>

<script>
// import moment from 'moment-timezone';
import AppCardPropriedadeSkeleton from './Skeleton.vue';

export default {
  name: 'AppCardPropriedade',

  components: {
    AppCardPropriedadeSkeleton,
  },

  methods: {
    // FormataData(date) {
    //   // return moment(date, 'YYYY-MM-DD').format('DD/MM-HH:MM');
    //   return moment.tz(date, 'America/Sao_Paulo').local().format('DD/MM-HH:mm');
    // },

    $_temDescricao(value) {
      // VUE não suporta optional chaining direto no template.
      if (value.produto?.prodescricao) {
        return true;
      }
      return false;
    },
  },

  props: {
    value: Object,
    actions: Boolean,
  },
};
</script>
/<style scoped>
#principal{
  display: block;
}

.flex-container {
  display: flex;
}

.flex-container > * {
  margin-right: 3px;
}
</style>
