import router from '@/router';

export function setQueryValue(key, value) {
  const query = { ...router.currentRoute.query };
  if (!value) delete query[key];
  else query[key] = value;

  const route = router.resolve({
    ...router.currentRoute,
    query,
  });

  if (route.href === router.currentRoute.fullPath) {
    return;
  }

  router.replace(route.href);
}

export function getQueryValue(key, defaultValue) {
  return router.currentRoute.query[key] ?? defaultValue ?? null;
}

export function getNumberQueryValue(key, defaultValue) {
  return Number(getQueryValue(key, defaultValue ?? 0));
}

export function getBooleanQueryValue(key, defaultValue) {
  return getQueryValue(key, String(defaultValue)) === 'true';
}

export function setSortQueryValue(key, field, desc) {
  setQueryValue(key, `${desc ? '-' : '+'}${field}`);
}

export function getSortQueryValue(key, defaultValue) {
  const sortQueryValue = getQueryValue(key, defaultValue ?? '');
  const [, order = '+', field] = /^(\+|-)?(.*)$/.exec(sortQueryValue);
  return { field: field.trim(), order: order === '-' };
}
