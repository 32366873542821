<script>
import VueVirtualScrollList from 'vue-virtual-scroll-list';
import CoreInfinitScrollItem from './Item.vue';

export default {
  name: 'CoreInfinitScroll',

  props: {
    items: Array,
    options: Object,
    serverItemsLength: [Number, String],

    loading: Boolean,

    itemKey: {
      type: String,
      default: 'id',
    },

    buffer: {
      type: Number,
      default: 25,
    },
  },

  methods: {
    $_onScroll($event, { end, start }) {
      if (end !== this.$_lastPadFront && end === this.items.length - 1) {
        const maxPage = Math.ceil(this.serverItemsLength / this.options.itemsPerPage);

        if (this.options.page === maxPage) return;
        this.$_nextPage();
      } else if (start !== this.$_lastPadBehind && start === 0) {
        if (this.options.page === 1) return;
        this.$_previousPage();

        console.log('riquena', 'next page');
      }

      this.$_lastPadFront = end;
      this.$_lastPadBehind = start;
    },

    $_previousPage() {
      if (this.options.page === 1) return;

      const options = JSON.parse(JSON.stringify(this.options ?? {}));
      options.page -= 1;

      this.$emit('update:options', options);
    },

    $_nextPage() {
      const currentTotal = ((this.options.page - 1) * this.options.itemsPerPage);
      const isLastPage = currentTotal >= this.serverItemsLength;
      if (isLastPage) return;

      const options = JSON.parse(JSON.stringify(this.options ?? {}));
      options.page += 1;
      console.log('nestpage', 'nestpage');
      this.$emit('update:options', options);
    },
  },

  created() {
    this.$_lastPadFront = 0;
    this.$_lastPadBehind = 0;
  },

  render(createElement) {
    const childs = [];

    if (this.loading) {
      childs.push(createElement('div', { slot: 'footer' }, this.$slots.loading));
    }

    return createElement(VueVirtualScrollList, {
      staticClass: 'core-infinite-scroll',
      ref: 'virtualScroll',
      props: {
        dataKey: this.itemKey,
        dataSources: this.items,
        dataComponent: CoreInfinitScrollItem,

        pageMode: true,
        keeps: this.buffer,

        itemScopedSlots: {
          default: this.$scopedSlots.default,
        },
      },
      on: {
        scroll: this.$_onScroll,
      },
    }, childs);
  },
};
</script>

<style lang="scss" scoped>
.core-infinite-scroll {
  height: 100%;
  width: 100%;
}
</style>
