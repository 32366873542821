function modelVeiculo(item) {
  return {
    veiidveiculo: item.veiidveiculo,
    veiplaca: item.veiplaca,
    veimarca: item.veimarca,
    veitipo: item.veitipo,
    veidataatualizacao: item.veidataatualizacao,
    veiidnuvem: item.id,
    veitipomaquinario: item.veitipomaquinario,
  };
}

function modelProduto(item) {
  return {
    proidproduto: item.proidproduto,
    prodescricao: item.prodescricao,
    procodigo: item.procodigo,
    provalor: item.provalor,
    prodataatualizacao: item.prodataatualizacao,
    proidnuvem: item.id,
  };
}

function modelAtividade(item) {
  return {
    ataidatividadeabastecimento: item.ataidatividadeabastecimento,
    atadescricao: item.atadescricao,
    atadataatualizacao: item.atadataatualizacao,
    ataidnuvem: item.id,
  };
}

function modelOperacao(item) {
  return {
    opeidoperacao: item.opeidoperacao,
    opedescricao: item.opedescricao,
    opedataatualizacao: item.opedataatualizacao,
    opeidnuvem: item.id,
  };
}

function modelPropriedade(item) {
  return {
    proidpropriedade: item.proidpropriedade,
    pronome: item.pronome,
    prodataatualizacao: item.prodataatualizacao,
    proidnuvem: item.id,
  };
}

function modelLocalMovEstoque(item) {
  return {
    lmeidlocalmovimentacaoestoque: item.lmeidlocalmovimentacaoestoque,
    lmedescricao: item.lmedescricao,
    lmeidproduto: item.lmeidproduto,
    lmedataatualizacao: item.lmedataatualizacao,
    lmeidnuvem: item.id,
  };
}

function modelPessoa(item) {
  return {
    pesidpessoa: item.pesidpessoa,
    pesnome: item.pesnome,
    pescpf: item.pescpf,
    pesnomefantasia: item.pesnomefantasia,
    pesidnuvem: item.id,
    pesdataatualizacao: item.pesdataatualizacao,
  };
}

function modelLogExclusao(item) {
  return {
    loedataexclusao: item.loedataexclusao,
  };
}

function modelMovAbastecimento(item) {
  return {
    moatipooperacao: item.moatipooperacao,
    moaidveiculo: item.moaidveiculo,
    moaidpessoa: item.moaidpessoa,
    moatipoodometro: item.moatipoodometro,
    moaodometro: item.moaodometro,
    moaquantidade: item.moaquantidade,
    moaidprodutoorigem: item.moaidprodutoorigem,
    moaidpessoaorigem: item.moaidpessoaorigem,
    moaidpropriedade: item.moaidpropriedade,
    moaidatividade: item.moaidatividade,
    moaobservacao: item.moaobservacao,
    moaidlocalarmazenamentoorigem: item.moaidlocalarmazenamentoorigem,
    moadatacriacao: item.moadatacriacao,
    moaidnuvem: item.id,
    moaidusuario: item.moaidusuario,
  };
}

// ESLint prefere default, mas não permite exportar variavel como default;

export const WSModel = {
  veiculo: modelVeiculo,
  produto: modelProduto,
  atividadeabastecimento: modelAtividade,
  operacao: modelOperacao,
  propriedade: modelPropriedade,
  localmovimentacaoestoque: modelLocalMovEstoque,
  pessoa: modelPessoa,
  logexclusao: modelLogExclusao,
  movabastecimento: modelMovAbastecimento,
};
