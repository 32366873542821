<template>
  <app-card-frota-skeleton v-bind="$attrs" v-on="$listeners" :actions="actions"
  @click="$_selecionaVeiculo(value)">
    <template #contrato >
      <div class="superior">
        <div>
          {{ value.veiplaca }}
        </div>
       <div v-if="value.veifrota">
        - {{ value.veifrota }}
       </div>
       <div v-if="value.veitipo">
        - Tipo: {{ value.veitipo }}
       </div>
      </div>
     </template>
    <template #detalhe1>
      <div v-if="value.veimarca">{{value.veimarca }}</div>
    </template>

    <slot />
  </app-card-frota-skeleton>
</template>

<script>
import { ABASTECIMENTO_STORAGE } from '@/constants/STORAGE';
import { ROUTE_CADASTRO } from '@/constants/ROUTES';
// import moment from 'moment-timezone';
import AppCardFrotaSkeleton from './Skeleton.vue';

export default {
  name: 'AppCardFrota',

  components: {
    AppCardFrotaSkeleton,
  },

  methods: {
    // FormataData(date) {
    //   // return moment(date, 'YYYY-MM-DD').format('DD/MM-HH:MM');
    //   return moment.tz(date, 'America/Sao_Paulo').local().format('DD/MM-HH:mm');
    // },

    $_temDescricao(value) {
      // VUE não suporta optional chaining direto no template.
      if (value.produto?.prodescricao) {
        return true;
      }
      return false;
    },

    async $_selecionaVeiculo(veiculo) {
      sessionStorage.setItem(ABASTECIMENTO_STORAGE.frota, JSON.stringify(veiculo));
      sessionStorage.setItem(ABASTECIMENTO_STORAGE.tipo, veiculo.veitipo);
      this.$router.replace({
        ...this.$route,
        name: ROUTE_CADASTRO.name,
      });
    },
  },

  props: {
    value: Object,
    actions: Boolean,
  },
};
</script>
/<style scoped>
#principal{
  display: block;
}

.flex-container {
  display: flex;
}

.flex-container > * {
  margin-right: 3px;
}

.superior {
  display: flex;
}
</style>
