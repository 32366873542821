<template>
  <v-btn icon
    :large="large"

    @click="$_onClick"
  >
    <v-icon>{{ $_icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'CoreBtnToggle',

  props: {
    value: null,
    large: Boolean,

    activeIcon: {
      type: String,
      required: true,
    },

    inactiveIcon: {
      type: String,
      required: true,
    },
  },

  computed: {
    $_icon() {
      return this.value ? this.activeIcon : this.inactiveIcon;
    },
  },

  methods: {
    $_onClick($event) {
      this.$emit('input', !this.value);
      this.$emit('click', $event);
    },
  },
};
</script>
