import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import users from './modules/users';
import aberturalote from './modules/aberturalote';
import empresa from './modules/empresa';
import permissao from './modules/permissao';

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {
    async $_setup({ dispatch }) {
      await dispatch('auth/$_setup');
    },
  },

  modules: {
    auth,
    users,
    aberturalote,
    empresa,
    permissao,
  },
});
