export const TOKEN = 'auth:token';

export const TOKENLOCAL = {
  tokenlocal: 'auth:tokenlocal'
}

export const MODULOS_STORAGE = {
  modulos: 'permitidos',
};

export const USER_STORAGE = {
  usesenha: 'use-senha',
  localtoken: 'localtoken',
  useidnuvem: 'user:useidnuvem'
};

export const ABASTECIMENTO_STORAGE = {
  operacao: 'abastecimento:operacao',
  frota: 'abastecimento:frota',
  operadora: 'abastecimento:operadora',
  odometro: 'abastecimento:odometro',
  quantidade: 'abastecimento:quantidade',
  origem: 'abastecimento:origem',
  operadorb: 'abastecimento:operadorb',
  atividade: 'abastecimento:atividade',
  tipo: 'abastecimento:tipo',
  propriedade: 'abastecimento:propriedade',
  produto: 'abastecimento:produto',
  valor: 'abastecimento:valor',
  descricao: 'abastecimento:descricao',
  data: 'abastecimento:data',
  idabastecimento: 'abastecimento:idabastecimento',
  createdBefore: 'abastecimento:createdBefore',
  createdFrom: 'abastecimeto:createdFromr'
};

export const FROTA_STORAGE = {
  tipo: 'frota:tipo',
  placa: 'frota:placa',
};

export const OPERADOR_STORAGE = {
  tipo: 'operador:tipo',
};

export const ORIGEM_STORAGE = {
  parametro: 'apagar:parametro',
  page: 'apagar:page',
  sort: 'apagar:sort',
  search: 'apagar:search',
  textofiltro: 'apagar:textofiltro',
};
