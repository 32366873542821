<template>
  <core-view>
    <core-data-pagination
      ref="dataPagination"
      :loading="loading"
      :load-handler="$_load"
      :options.sync="options"
    >
      <template #card-skeleton-loader="{ attrs, on }">
        <app-card-operador-skeleton v-bind="attrs" v-on="on" actions />
      </template>

      <template #card="{ item, attrs, on }">
        <app-card-operador actions
          id='CARD-DETALHE'
          v-bind="attrs"
          v-on="on"
          :value="item"
          @click="$_onClickItem($event, item)" >
        </app-card-operador>
      </template>
    </core-data-pagination>
  </core-view>
</template>

<script>
import { mapActions } from 'vuex';

import { GENERIC_QUERIES, OPERADOR_QUERIES } from '@/constants/QUERY_FILTERS';

import errorHandler from '@/utils/error-handler';
import deepEquals from '@/utils/deep-equals';

import RemoveItemDialog from '@/events/RemoveItemDialog';

import {
  getNumberQueryValue, getQueryValue, getSortQueryValue, setQueryValue, setSortQueryValue,
} from '@/services/query-string';

import CoreView from '@/components/core/view/Index.vue';
import CoreDataPagination from '@/components/core/new-data-pagination/Index.vue';

import AppCardOperadorSkeleton from '@/components/app/card/operador/Skeleton.vue';
import AppCardOperador from '@/components/app/card/operador/Index.vue';
import { OPERADOR_STORAGE } from '@/constants/STORAGE';

import db from '@/plugins/dexie';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

export default {
  name: 'ViewRecebimento',

  components: {
    CoreView,
    CoreDataPagination,
    AppCardOperadorSkeleton,
    AppCardOperador,
  },

  data() {
    if (this.$route.params.clearSession) {
      sessionStorage.removeItem(OPERADOR_STORAGE.sort);
      sessionStorage.removeItem(OPERADOR_STORAGE.page);
      sessionStorage.removeItem(OPERADOR_STORAGE.search);
      sessionStorage.removeItem(OPERADOR_STORAGE.placa);
    }

    return {
      loading: false,
      newRecebimentoRoute: {
        params: { recebimentoId: 'new' },
      },

      filters: {
        placa: getSessionStorage(OPERADOR_STORAGE.placa),
      },

      options: {
        page: getSessionStorage(OPERADOR_STORAGE.page, 1),
        itemsPerPage: 10,
        sortBy: ['recnumerocontrolerecbto'],
        sortDesc: [true],
      },
    };
  },

  methods: {
    ...mapActions({
      $_geraModulo: 'modulos/metodoAcesso',
    }),

    async $_load() {
      this.loading = true;

      try {
        sessionStorage.setItem(OPERADOR_STORAGE.placa, this.filters.placa);
        console.log(this.filters, 'index');
        return await db.pessoa.toArray().then((item) => ({ data: item }));
      } catch (err) {
        errorHandler.call(this, err);
        return { data: [], total: 0 };
      } finally {
        this.loading = false;
      }
    },

    async $_onClickItem() {
      // this.$router.push({
      //   mouseEvent,
      //   name: ROUTE_RECEBIMENTO_ID.name,
      //   params: { recebimentoId: item.id },
      // });
    },

    $_onClickRemoveItem(mouseEvent, item) {
      RemoveItemDialog.emit({
        message: this.$t('messages.removeUserMessage'),
        alertMessage: this.$t('messages.removeUserAllertMessage'),
        confirmText: item.id,
        onConfirmHandler: () => new Promise((resolve) => {
          setTimeout(() => {
            resolve(false);
          }, 1000 * 5);
        }),
        onCancelHandler() {},
      });
    },
  },

  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.options.page = getNumberQueryValue(GENERIC_QUERIES.page, this.options.page);

        const defaultSort = `${this.options.sortDesc[0] ? '+' : '-'}${this.options.sortBy[0]}`;
        const { field, order } = getSortQueryValue(GENERIC_QUERIES.sort, defaultSort);
        this.options.sortBy[0] = field;
        this.options.sortDesc[0] = order;

        const filters = {
          search: getQueryValue(GENERIC_QUERIES.search, null),
          placa: getQueryValue(
            OPERADOR_QUERIES.placa,
            this.filters.placa,
          ),
        };

        if (!deepEquals(this.filters, filters)) {
          this.filters.placa = filters.placa;
          if (this.$refs.dataPagination) {
            this.$refs.dataPagination.reload();
          }
        }
      },
    },

    options: {
      deep: true,
      handler() {
        setQueryValue(GENERIC_QUERIES.page, this.options.page);
        setSortQueryValue(GENERIC_QUERIES.sort, this.options.sortBy[0], this.options.sortDesc[0]);
        setQueryValue(OPERADOR_QUERIES.placa, this.filters.placa);
      },
    },
  },
};
</script>
<style>
#resumo{
  float: right;
}
</style>
