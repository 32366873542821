<template>
  <layout-default-appbar :back-to="rotaabastecimento">
    Histórico Abastecimento
  </layout-default-appbar>
</template>

<script>
import LayoutDefaultAppbar from '@/layouts/default/AppBar.vue';

import { ROUTE_ABASTECIMENTO } from '@/constants/ROUTES';

export default {
  name: 'ViewFrotaAppBar',

  components: {
    LayoutDefaultAppbar,
  },

  data() {
    return {
      rotaabastecimento: ROUTE_ABASTECIMENTO,
    };
  },
};
</script>
