<template>
  <v-dialog scrollable
    v-model="isActive"
    max-width="512"
    content-class="rounded-xl ma-3"
    :persistent="loadings.confirm || loadings.cancel"
  >
    <v-card>
      <v-card-title class="red--text">
        {{ ('messages.removeTitle') }}
      </v-card-title>

      <v-card-text>
        <v-alert colored-border dense
          v-if="alertTitle || alertMessage"
          color="red"
          class="red lighten-5"
          border="left"
          type="warning"
        >
          <div>{{ alertTitle }}</div>
          <div v-if="alertTitle && alertMessage" class="mt-2" />
          <div v-if="alertMessage" class="mt-2" v-html="alertMessage" />
        </v-alert>

        <div>{{ message }}</div>

        <div v-if="confirmText" class="mt-3">
          {{ ('messages.removeConfirm') }}
          <code>{{ confirmText }}</code>

          <core-text-field hide-details autofocus
            v-model="confirmValue"
            color="red"
            class="mt-3"
            :disabled="loadings.confirm || loadings.cancel"
          />
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn rounded depressed
          class="pr-3"
          :loading="loadings.cancel"
          :disabled="loadings.confirm"
          @click="$_onClickCancel"
        >
          <v-icon left>mdi-close</v-icon>
          Cancelar
        </v-btn>

        <v-btn rounded depressed
          color="red"
          class="pr-3"
          :loading="loadings.confirm"
          :dark="!$_confirmBtnDisabled && !loadings.cancel"
          :disabled="$_confirmBtnDisabled || loadings.cancel"
          @click="$_onClickConfirm"
        >
          <v-icon left>mdi-check</v-icon>
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RemoveItemDialog from '@/events/RemoveItemDialog';

import CoreTextField from '@/components/core/text-field/Index.vue';

export default {
  name: 'AppDialogConfirm',

  components: {
    CoreTextField,
  },

  data() {
    return {
      isActive: false,
      loadings: {
        confirm: false,
        cancel: false,
      },

      message: null,

      alertTitle: null,
      alertMessage: null,

      confirmText: null,
      confirmValue: null,
    };
  },

  computed: {
    $_confirmBtnDisabled() {
      if (!this.confirmText) return false;

      return String(this.confirmText).trim() !== String(this.confirmValue).trim();
    },
  },

  methods: {
    $_handler({
      detail: {
        message, confirmText, alertMessage, onConfirmHandler, onCancelHandler,
      },
    }) {
      this.onConfirmHandler = onConfirmHandler;
      this.onCancelHandler = onCancelHandler;

      this.message = message ?? ('messages.removeDefaultMessage');
      this.confirmText = confirmText ?? null;
      this.confirmValue = null;

      this.alertMessage = alertMessage ?? null;
      this.alertTitle = (alertMessage && ('messages.removeAllertTitle')) ?? null;

      this.loadings.confirm = false;
      this.loadings.cancel = false;
      this.isActive = true;
    },

    async $_onClickConfirm($event) {
      if (!this.onConfirmHandler) {
        this.isActive = false;
        return;
      }

      const handlerResult = this.onConfirmHandler($event);

      if (handlerResult instanceof Promise) {
        this.loadings.confirm = true;
        this.isActive = !!(await handlerResult);
        this.loadings.confirm = false;
        return;
      }

      this.isActive = false;
    },

    async $_onClickCancel($event) {
      if (!this.onCancelHandler) {
        this.isActive = false;
        return;
      }

      const handlerResult = this.onCancelHandler($event);

      if (handlerResult instanceof Promise) {
        this.loadings.cancel = true;
        this.isActive = !!(await handlerResult);
        this.loadings.cancel = false;
        return;
      }

      this.isActive = false;
    },
  },

  created() {
    RemoveItemDialog.on(this.$_handler);
  },

  beforeDestroy() {
    RemoveItemDialog.off(this.$_handler);
  },
};
</script>
