<template>
  <core-view class="flex-column" fill-height>
    <template>
    <div class="center" v-if="!loading">
      <form>
        <div v-show="formAtual === 1">
          <div class="linha">
            <btn-auto-complete label="Operação"
            :dados="autocomplete.operacao" icon="mdi-chevron-down"
            v-model="formulario.operacao" itemLabel="opedescricao"
            @change="$_toStorage('operacao','opedescricao')">
            </btn-auto-complete>
          </div>
          <div class="linha">
            <btn-auto-complete :redirect="rotaFiltros[0]" label="Frota (Veículo/Maquinário)"
            :dados="autocomplete.frota" v-model="formulario.frota"
            itemLabel="veiplaca" @change="$_toStorage('frota', 'veiplaca')" hide-details
            :frota="true">
            </btn-auto-complete>
          </div>
          <div class="linha" @click="$_setOperador(1)">
            <btn-auto-complete :redirect="rotaFiltros[1]" label="Operador"
            :dados="autocomplete.operador" v-model="formulario.operadora"
            itemLabel="pesnome" @change="$_toStorage('operadora','pesnome')"
            paramappend=1
            >
            </btn-auto-complete>
          </div>
          <div class="linha-justificada">
            <new-text-field-money background-color="white"
            :label="labelodometro" :max="11"
            v-model="formulario.odometro" @emitblur="$_ToStorageOdometro">
            </new-text-field-money>
            <new-text-field-money background-color="white" label="Qtde (LTs)"
            v-model="formulario.quantidade" :max="9"
            @emitblur="$_toStorageQuantidade">
            </new-text-field-money>
          </div>
          <br/>
          <div class="linha">
            <btn-auto-complete :redirect="rotaFiltros[2]" label="Origem Abastecimento"
            :dados="autocomplete.origem" v-model="formulario.origem"
            itemLabel="lmedescricao" height=40
            @change="$_toStorage('origem','lmedescricao'),$_getProduto()">
            </btn-auto-complete>
          </div>
          <div class="linha">
            <new-text-field label="Produto" readonly v-model="formulario.produto"
            hide-details
            ></new-text-field>
          </div>
          <div class="linha">
            <btn-auto-complete :redirect="rotaFiltros[1]" label="Operador"
            :dados="autocomplete.operador" v-model="formulario.operadorb"
            itemLabel="pesnome" @change="$_toStorage('operadorb','pesnome')"
            paramappend=2
            >
            </btn-auto-complete>
          </div>
        </div>
        <div v-show="formAtual === 2">
          <div class="linha">
            <btn-auto-complete label="Local (Propriedade)"
            icon="mdi-chevron-down" :dados="autocomplete.propriedade"
            v-model="formulario.propriedade" itemLabel="pronome"
            @change="$_toStorage('propriedade','pronome')">
            </btn-auto-complete>
          </div>
          <div class="linha">
            <btn-auto-complete label="Atividade"
            icon="mdi-chevron-down" :dados="autocomplete.atividade"
            v-model="formulario.atividade" itemLabel="atadescricao"
            @change="$_toStorage('atividade','atadescricao')">
            </btn-auto-complete>
          </div>
          <div class="linha">
            <v-textarea maxlength=200 outlined counter v-model='formulario.descricao'
            background-color="white" label="Descrição"
            @change="$_toStorageSimples('descricao')" >
            </v-textarea>
          </div>
        </div>
        <div class="divinferior">
          <v-btn height=45 color="green botaoinferior" @click="$_trocaFormulario()"
          v-if="formAtual === 1">
            Próximo
          </v-btn>
          <div v-else>
          <v-btn height=45 color="grey botaoinferior botaomargim" @click="$_trocaFormulario()">
            Voltar
          </v-btn>
           <v-btn height=45 color="green botaoinferior" @click="$_salvaDados()">
            Salvar
          </v-btn>
          </div>
        </div>
      </form>
    </div>
 </template>

</core-view>
</template>

<script>
// import errorHandler from '@/utils/error-handler';

import {
  ROUTE_FROTA, ROUTE_OPERADOR, ROUTE_ORIGEM, ROUTE_PROPRIEDADE, ROUTE_ATIVIDADE,
  ROUTE_ABASTECIMENTO,
} from '@/constants/ROUTES';

import { ABASTECIMENTO_STORAGE, OPERADOR_STORAGE } from '@/constants/STORAGE';

import CoreView from '@/components/core/view/Index.vue';

import NewTextField from '@/components/core/new-text-field/Index.vue';
import NewTextFieldMoney from '@/components/core/new-text-field-money/Index.vue';
import BtnAutoComplete from '@/components/core/btn-auto-complete/Index.vue';

import errorHandler from '@/utils/error-handler';

import moment from 'moment-timezone';

import db from '@/plugins/dexie';
// import moment from 'moment';

import { SetupWebSocket, WsEnviaDados } from '../../utils/ws/websocket';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

function getSessionStorageParse(key, defaultValue) {
  const retorno = sessionStorage.getItem(key) ?? defaultValue;
  try {
    return JSON.parse(retorno);
  } catch {
    return retorno;
  }
}

export default {
  name: 'ViewHome',

  components: {
    CoreView,
    NewTextField,
    BtnAutoComplete,
    NewTextFieldMoney,
  },

  data() {
    return {
      rotaFiltros: [
        ROUTE_FROTA,
        ROUTE_OPERADOR,
        ROUTE_ORIGEM,
        ROUTE_ATIVIDADE,
        ROUTE_PROPRIEDADE,
      ],

      formAtual: 1,

      formulario: {
        operacao: '',
        data: moment().format('YYYY-MM-DD'),
        odometro: '',
        quantidade: '',
        frota: '',
        hora: '',
        tipo: '',
        atividade: '',
        origem: '',
        produto: '',
        operadora: '',
        operadorb: '',
        propriedade: '',
        descricao: '',
      },

      autocomplete: {
        frota: null,
        operacao: null,
        origem: null,
        operador: null,
        atividade: null,
        propriedade: null,
      },

      loading: true,
    };
  },

  methods: {

    $_setOperador(valor) {
      sessionStorage.setItem(OPERADOR_STORAGE.tipo, valor);
    },

    $_salvaDados() {
      const idnuvem = getSessionStorage('user:useidnuvem', null);

      let now = moment();
      now = now.subtract(3,'hours');

      const novoAbastecimento = {
        abafrota: (getSessionStorageParse(ABASTECIMENTO_STORAGE.frota, '')).veiplaca,
        abaoperacao: (getSessionStorageParse(ABASTECIMENTO_STORAGE.operacao, '')).opedescricao,
        abadata: new Date(),
        abaoperadorveiculo: (getSessionStorageParse(ABASTECIMENTO_STORAGE.operadora, '')).pesnome,
        abaodometro: getSessionStorage(ABASTECIMENTO_STORAGE.odometro, ''),
        abaquantidade: getSessionStorage(ABASTECIMENTO_STORAGE.quantidade, ''),
        abaoperador: (getSessionStorageParse(ABASTECIMENTO_STORAGE.operadorb, '')).pesnome,
        abaorigem: (getSessionStorageParse(ABASTECIMENTO_STORAGE.origem, '')).lmedescricao,
        abaproduto: getSessionStorage(ABASTECIMENTO_STORAGE.produto, ''),
        abadescricao: getSessionStorage(ABASTECIMENTO_STORAGE.descricao, ''),
        abalocal: (getSessionStorageParse(ABASTECIMENTO_STORAGE.propriedade, '')).pronome,
        abaatividade: (getSessionStorageParse(ABASTECIMENTO_STORAGE.atividade, '')).atadescricao,
        abatipoodometro: this.labelodometro,
      };
      let tipopdometro = null;
      if (this.labelodometro === 'Odômetro (HH)') {
        tipopdometro = 'HH';
      } else {
        tipopdometro = 'Km';
      }
      const novoAbastecimentoNuvem = {
        moatipooperacao: this.formulario.operacao.id,
        moaidveiculo: this.formulario.frota.veiidveiculo,
        moaidpessoa: this.formulario.operadora.pesidpessoa,
        moatipoodometro: tipopdometro,
        moaodometro: String(this.formulario.odometro),
        moaquantidade: String(this.formulario.quantidade),
        moaidprodutoorigem: this.formulario.origem.lmeidproduto,
        moaidpessoaorigem: this.formulario.operadorb.pesidpessoa,
        moaidpropriedade: this.formulario.propriedade.proidpropriedade,
        moaidatividade: this.formulario.atividade.ataidatividadeabastecimento,
        moaobservacao: this.formulario.descricao,
        moaidlocalarmazenamentoorigem: this.formulario.origem.lmeidlocalmovimentacaoestoque,
        moadatacriacao: now.toISOString(),
        moaidnuvem: 'null',
        moaidusuario: idnuvem,
      };

      if (Object.values(novoAbastecimento).every((item) => item !== '')) {
        const validacao = db.abastecimento.add(novoAbastecimento);
        db.movimentacaoabastecimento.add(novoAbastecimentoNuvem);
        WsEnviaDados({ tipo: 'postAbastecimento', msg: novoAbastecimentoNuvem });

        ['frota', 'operacao', 'data', 'operadora', 'odometro', 'quantidade',
          'operadorb', 'origem', 'descricao', 'propriedade', 'produto', 'atividade',
        ].forEach((item) => {
          sessionStorage.removeItem(ABASTECIMENTO_STORAGE[item]);
        });

        if (validacao) {
          this.$router.replace({
            ...this.$route,
            name: ROUTE_ABASTECIMENTO.name,
          });
        }
      } else {
        errorHandler('Alguns campos não foram preenchidos');
      }
    },

    async $_findDb() {
      this.autocomplete.origem = await db.localmovimentacaoestoque.toArray();
      this.autocomplete.frota = await db.veiculo.toArray();
      this.autocomplete.operador = await db.pessoa.toArray();
      this.autocomplete.atividade = await db.atividadeabastecimento.toArray();
      this.autocomplete.propriedade = await db.propriedade.toArray();
      this.autocomplete.operacao = await db.operacao.toArray();
      this.loading = false;
      return null;
    },

    async $_setupCampos() {
      this.formulario.frota = getSessionStorageParse(ABASTECIMENTO_STORAGE.frota, '');
      this.formulario.tipo = getSessionStorage(ABASTECIMENTO_STORAGE.tipo, '');
      this.formulario.operacao = getSessionStorageParse(ABASTECIMENTO_STORAGE.operacao, '');
      this.formulario.origem = getSessionStorageParse(ABASTECIMENTO_STORAGE.origem, '');
      this.formulario.operadora = getSessionStorageParse(ABASTECIMENTO_STORAGE.operadora, '');
      this.formulario.operadorb = getSessionStorageParse(ABASTECIMENTO_STORAGE.operadorb, '');
      this.formulario.atividade = getSessionStorageParse(ABASTECIMENTO_STORAGE.atividade, '');
      this.formulario.propriedade = getSessionStorageParse(ABASTECIMENTO_STORAGE.propriedade, '');
      this.formulario.produto = getSessionStorage(ABASTECIMENTO_STORAGE.produto, '');
      this.formulario.odometro = getSessionStorage(ABASTECIMENTO_STORAGE.odometro, '');
      this.formulario.quantidade = getSessionStorage(ABASTECIMENTO_STORAGE.quantidade, '');
      this.formulario.descricao = getSessionStorage(ABASTECIMENTO_STORAGE.descricao, '');
    },

    async $_toStorage(tabela) {
      sessionStorage.setItem(ABASTECIMENTO_STORAGE[tabela], JSON.stringify(this.formulario[tabela]));
    },

    async $_toStorageQuantidade(valor) {
      sessionStorage.setItem(ABASTECIMENTO_STORAGE.quantidade, valor);
      this.formulario.quantidade = valor;
    },

    async $_ToStorageOdometro(valor) {
      sessionStorage.setItem(ABASTECIMENTO_STORAGE.odometro, valor);
      this.formulario.odometro = valor;
    },

    async $_toStorageSimples(campo) {
      sessionStorage.setItem(ABASTECIMENTO_STORAGE[campo], this.formulario[campo]);
    },

    async $_getProduto() {
      // Uma computed seria ideal, mas não é possivel usar async com computed sem libs externas.
      if (this.formulario.origem?.lmeidproduto) {
        const produto = db.produto.where({
          proidproduto: this.formulario.origem.lmeidproduto,
        }).first();

        produto.then((el) => {
          this.formulario.produto = (el?.prodescricao) ? el.prodescricao : '';
          sessionStorage.setItem(ABASTECIMENTO_STORAGE.produto, this.formulario.produto);
        });
      }
    },

    async $_trocaFormulario() {
      this.formAtual = (this.formAtual === 2) ? 1 : 2;
      this.icone = (this.formAtual === 2) ? 'mdi-arrow-left-thick' : 'mdi-arrow-right-thick';
    },
  },

  created() {
    // this.formulario.hora = moment().format('HH:mm');
    this.$_findDb();
    this.$_setupCampos();
    SetupWebSocket();
  },

  computed: { labelodometro() { return (this.formulario.frota.veitipo === 2) ? 'Odômetro (HH)' : 'Odômetro (Km)'; } },
};
</script>

<style scoped>
  .v-sheet--offset {
    top: 5px;
    position: relative;
  }

  .center {
    width: 100%;
    text-align: center;
  }

  .linha {
    display: flex;
        /* border: 1px solid red; */
  }

  .linha-justificada {
    display: flex;
    justify-content: space-around;
  }

  .divinferior {
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 5px;
    right: 0%;
  }

  .botaoinferior {
    color: white;
  }

  .botaomargim {
    margin-right: 15%;
  }

  @media screen and (min-width:600px) {
    .tablet {
      display: flex;
    }
  }

</style>
