export const GENERIC_QUERIES = {
  search: 'q',
  page: 'page',
  sort: 'sort',
};

export const ABASTECIMENTO_QUERIES = {
  createdFrom: 'created-from',
  createdBefore: 'created-before',
}

export const VISITANTES_QUERIES = {
  createdFrom: 'created-from',
  createdBefore: 'created-before',
  statusVisitante: 'status-visitantes',
};

export const RECEBIMENTO_QUERIES = {
  createdFrom: 'created-from',
  createdBefore: 'created-before',
  codigopessoa: 'codigopessoa',
};

export const LOTES_QUERIES = {
  createdFrom: 'created-from',
  createdBefore: 'created-before',
  prefixo: 'prefixo',
  nrocontrole: 'nrocontrole',
};

export const RESUMO_QUERIES = {
  createdFrom: 'created-from',
  createdBefore: 'created-before',
  codigopessoa: 'codigopessoa',
};

export const ESTOQUE_QUERIES = {
  createdFrom: 'created-from',
  createdBefore: 'created-before',
};

export const PRODUCAO_QUERIES = {
  createdFrom: 'created-from',
  createdBefore: 'created-before',
};

export const FILA_QUERIES = {
  createdFrom: 'created-from',
  createdBefore: 'created-before',
  statusFila: 'status-fila',
  tipoFila: 'tipo-fila',
};

export const CONSULTA_QUERIES = {
  createdFrom: 'created-from',
  createdBefore: 'created-before',
};

export const CADASTRO_QUERIES = {
  createdFrom: 'created-from',
  createdBefore: 'created-before',
};

export const IMAGENS_QUERIES = {
  createdFrom: 'created-from',
  createdBefore: 'created-before',
};

export const A_PAGAR_QUERIES = {
  createdFrom: 'created-from',
  createdBefore: 'created-before',
  codigopessoa: 'codigopessoa',
};

export const A_RECEBER_QUERIES = {
  createdFrom: 'created-from',
  createdBefore: 'created-before',
  codigopessoa: 'codigopessoa',
};

export const IMG_CONTRATO_QUERIES = {
  numerocontrato: 'numero-contrato',
  sufixo: 'sufixo',
};

export const FROTA_QUERIES = {
  tipo: 'tipo',
  placa: 'placa',
};

export const OPERADOR_QUERIES = {
  codigopessoa: 'codigopessoa',
};

export const ORIGEM_QUERIES = {
  default: 'codigopessoa',
};
