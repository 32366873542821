export const nomesTabelas = [
  {
    tabela: 'localmovimentacaoestoque',
  },
  {
    tabela: 'veiculo',
  },
  {
    tabela: 'operacao',
  },
  {
    tabela: 'atividadeabastecimento',
  },
  {
    tabela: 'pessoa',
  },
  {
    tabela: 'propriedade',
  },
  {
    tabela: 'produto',
  },
  {
    tabela: 'movimentacaoabastecimento',
  },
  {
    tabela: 'logexclusao',
  },
];

export const prefixos = {
  atividadeabastecimento:
  {
    prefixo: 'ata',
  },
  localmovimentacaoestoque:
  {
    prefixo: 'lme',
  },
  veiculo:
  {
    prefixo: 'vei',
  },
  operacao:
  {
    prefixo: 'ope',
  },
  pessoa:
  {
    prefixo: 'pes',
  },
  propriedade:
  {
    prefixo: 'pro',
  },
  produto:
  {
    prefixo: 'pro',
  },
  movimentacaoabastecimento:
  {
    prefixo: 'moa',
  },
};
