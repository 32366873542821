import Vue from 'vue'
import VueTheMask from 'vue-the-mask';
import App from './App.vue'

import './registerServiceWorker'

import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import { setupBaseURL } from './services/api-recebimentoweb';
import { WSDefaultRoute } from './utils/ws/websocket'
// import { ROUTE_SIGN_IN } from './constants/ROUTES';

setupBaseURL();
WSDefaultRoute();

Vue.config.productionTip = false

Vue.use(VueTheMask);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')


// Promise.all([
//   setupBaseURL(),
//   setupApiOnResponseError(async (err) => {
//     if (err.response?.status === 401 && store.getters['auth/accessToken']) {
//       await store.dispatch('auth/unauthenticate');

//       if (router.currentRoute.path === '/') return;

//       router.push({
//         path: 'signin',
//         query: { origin: router.currentRoute.fullPath },
//       });
//     }

//     throw err;
//   }),

// ])
//   .then(() => {
//     store.dispatch('$_setup');
//   })
//   .then(() => {
//     new Vue({
//       router,
//       store,
//       vuetify,
//       render: h => h(App)
//     }).$mount('#app')
//   });

