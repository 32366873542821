<template>
  <div>
    <v-row class="mb-3" no-gutters>
      <br v-if="!near">

      <v-spacer />

      <core-btn-toggle
        v-if="!$_isMobile"
        v-model="showAsCard"
        active-icon="mdi-table"
        inactive-icon="mdi-card-text-outline"
      />
    </v-row>

    <template v-if="$_isMobile || showAsCard">
      <core-infinite-scroll
        :items="data"
        :loading="loading"
        :options.sync="$_options"
        :server-items-length="total"
      >
        <template #default="{ item }">
          <v-hover #default="{ hover }">
            <slot
              name="card"

              :item="item"
              :attrs="{
                class: {
                  'mb-3': true,
                  'on-hover': hover,
                },
                elevation: hover ? 12 : 2,
              }"
            />
          </v-hover>
        </template>

        <template #loading>
          <slot name="card-skeleton-loader" :attrs="{ key: 1, class: 'mb-3' }" />
        </template>
      </core-infinite-scroll>
    </template>

    <slot v-else
      name="data-table"

      :attrs="{
        mustSort: true,
        headers,
        items: data,
        options: $_options,
        serverItemsLength: total,
        loading,
        class: 'elevation-2 rounded-lg',
      }"

      :on="{
        'update:options': ($event) => $_options = $event,
      }"
    />
  </div>
</template>

<script>
import deepEquals from '@/utils/deep-equals';

import CoreBtnToggle from '@/components/core/btn-toggle/Index.vue';
import CoreInfiniteScroll from '@/components/core/infinite-scroll/Index.vue';

export default {
  name: 'NewDataPagination',

  components: {
    CoreBtnToggle,
    CoreInfiniteScroll,
  },

  props: {
    loading: Boolean,
    near: Boolean,
    loadHandler: Function,

    headers: Array,
    options: Object,
  },

  data() {
    return {
      showAsCard: false,

      data: [],
      total: 0,
    };
  },

  computed: {
    $_isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    $_options: {
      get() {
        return this.options;
      },
      set($value) {
        this.$emit('update:options', $value);
      },
    },
  },

  methods: {
    $_getDataKeys() {
      const dataKeys = {};
      Object.values(this.data).forEach(({ id }) => {
        dataKeys[id] = true;
      });
      console.log('$_getDataKeys()'); // <-- Esta iniciando quando não devia.
      return dataKeys;
    },

    async $_loadhandler() {
      const { data, total } = await this.loadHandler({
        limit: this.options.itemsPerPage,
        offset: (this.options.page - 1) * this.options.itemsPerPage,
        orderBy: this.options.sortBy
          .map((field, index) => `${field} ${this.options.sortDesc[index] ? 'desc' : 'asc'}`),
      });

      this.total = Number(total);
      console.log('$_loadhandler()');
      return data;
    },

    async $_setDataFromCurrentPage() {
      this.data = await this.$_loadhandler();
      console.log('$_setDataFromCurrentPage()');
    },

    async $_addDataFromNextPage() {
      const dataKeys = this.$_getDataKeys();

      const nextPage = (await this.$_loadhandler())
        .filter(({ id }) => !dataKeys[id]);

      this.data = this.data.concat(nextPage);
      console.log('$_addDataFromNextPage()'); // <-- Está iniciando quando não deveria.
    },

    async $_addDataFromPreviousPage() {
      const dataKeys = this.$_getDataKeys();

      const previousPage = (await this.$_loadhandler())
        .filter(({ id }) => !dataKeys[id]);

      this.data = previousPage.concat(this.data);
      console.log('$_addDataFromPreviousPage()'); // <-- Está iniciando quando não devia.
    },

    reload() {
      if (this.options.page === 1) {
        console.log('reload()');
        this.$_setDataFromCurrentPage();
        return;
      }

      this.$emit('update:options', {
        ...this.options,
        page: 1,
      });
    },
  },

  watch: {
    options: {
      deep: true,
      handler(newValue, oldValue) {
        if (this.loading || deepEquals(newValue, oldValue)) return;

        const reload = newValue.sortBy[0] !== oldValue.sortBy[0]
          || newValue.sortDesc[0] !== oldValue.sortDesc[0];

        if ((!this.$_isMobile && !this.showAsCard) || reload) {
          this.$_setDataFromCurrentPage();
          return;
        }

        if (newValue.page > oldValue.page) { // <-- Passo anterior
          this.$_addDataFromNextPage();
        } else {
          this.$_addDataFromPreviousPage();
        }
      },
    },
  },

  created() {
    if (this.$_isMobile) {
      const options = JSON.parse(JSON.stringify(this.$_options));
      options.page = options.page ?? 1;
      options.itemsPerPage = options.itemsPerPage ?? 10;
      options.sortBy = options.sortBy ?? [];
      options.sortDesc = options.sortDesc ?? [];
      options.sortBy = options.sortBy ?? [];
      options.forceUpdate = Date.now();

      this.$_options = options;
    }
  },
};
</script>
