import apiempresaweb from '@/services/api-recebimentoweb';

export default {
  namespaced: true,

  state: {
    list: [],
  },

  getters: {
    list: (state) => state.list,
  },

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
  actions: {
    async find(_, {
      where, ...params
    } = {}) {
      const [{ data }] = await Promise.all([
        apiempresaweb.get('empresa', { params: { ...where, ...params } }),
      ]);

      return data;
    },
  },

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
  mutations: {
    SET_LIST(state, list) {
      state.list = list ?? [];
    },
  },
};
