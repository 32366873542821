import { VDataTable } from 'vuetify/lib';

export default {
  name: 'CoreDataTable',

  extends: VDataTable,

  props: {
    actions: Boolean,
  },

  created() {
    this.$_oldGenDefaultSimpleRow = this.genDefaultSimpleRow;
    this.genDefaultSimpleRow = (...genDefaultSimpleRowArgs) => {
      const defaultSimpleRow = this.$_oldGenDefaultSimpleRow(...genDefaultSimpleRowArgs);
      const oldOnClickRow = defaultSimpleRow.data.on.click;

      defaultSimpleRow.data.on.click = (mouseEvent) => {
        const oldEmit = this.$emit;

        this.$emit = (emitEvent, item, data) => {
          oldEmit.call(this, emitEvent, mouseEvent, item, data);

          this.$emit = oldEmit;
        };

        oldOnClickRow();
      };

      return defaultSimpleRow;
    };
  },
};
