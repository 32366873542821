<template>
  <layout-default-filters @apply="$_applyQueryString">
    <template #superior>
    <div class='div-geral'>
      <div class='primeiro-filtro'>
        <v-form ref="form">
          <core-date-picker-field clearable
            v-model="filters.createdFrom"
            label="De"
          />

          <core-date-picker-field clearable
            v-model="filters.createdBefore"
            label="Até"
            :rules="createdBeforeRules"
          />
        </v-form>
      </div>
    </div>
    </template>
  </layout-default-filters>
</template>

<script>
import { mapActions } from 'vuex';

import { ABASTECIMENTO_QUERIES } from '@/constants/QUERY_FILTERS';
import { getQueryValue, setQueryValue } from '@/services/query-string';

import LayoutDefaultFilters from '@/layouts/default/NewFilters.vue';

import moment from 'moment';

import CoreDatePickerField from '@/components/core/date-picker-field/Index.vue';

export default {
  name: 'ViewsHomeFilters',

  components: {
    LayoutDefaultFilters,
    CoreDatePickerField,
  },

  data() {
    return {
      isValid: false,

      filters: {
        createdFrom: null,
        createdBefore: null,
      },

      createdBeforeRules: [
        () => {
          const from = new Date(this.filters.createdFrom);
          const to = new Date(this.filters.createdBefore);

          return to >= from || 'Data Final deve ser superior a Data Inicial';
        },
      ],
    };
  },

  methods: {
    ...mapActions({
      $_findFinanceiroDistinct: 'recebimento/findDistinct',
      $_findFinDistinctCodigo: 'recebimento/findDistinctCodigo',
    }),

    $_applyQueryString() {
      setQueryValue(ABASTECIMENTO_QUERIES.createdFrom, this.filters.createdFrom);
      setQueryValue(ABASTECIMENTO_QUERIES.createdBefore, this.filters.createdBefore);
    },

    $_FilterReset() {
      this.filters.createdFrom = getQueryValue(
        ABASTECIMENTO_QUERIES.createdFrom, this.filters.createdFrom,
      );
      this.filters.createdBefore = getQueryValue(
        ABASTECIMENTO_QUERIES.createdBefore,
        this.filters.createdBefore,
      );
    },
  },

  watch: {
    filters: {
      deep: true,
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      },
    },

    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.filters.createdFrom = getQueryValue(
          ABASTECIMENTO_QUERIES.createdFrom,
          this.filters.createdFrom,
        );

        this.filters.createdBefore = getQueryValue(
          ABASTECIMENTO_QUERIES.createdBefore,
          this.filters.createdBefore,
        );
      },
    },
  },

  created() {
      setQueryValue(ABASTECIMENTO_QUERIES.createdFrom, moment().format('YYYY-MM-DD'));
      setQueryValue(ABASTECIMENTO_QUERIES.createdBefore, moment().format('YYYY-MM-DD'));
  }
};
</script>

<style scoped>

  .div-geral {
    height: 0%;
  }

  .primeiro-filtro {
    transition: opacity 0.3s;
    position: relative;
    opacity: 1;
    z-index: 1;
  }


</style>
