import { SetupWebSocket, WSDefaultRoute, CloseWebSocket } from './ws/websocket';

let online = navigator.onLine;
let jaDefinido = false;

export async function IsOnline() {
  return online;
}

export function SetupIsOnline() {
  if (!jaDefinido) {
    window.addEventListener('offline', () => {
      console.log('Atualmente Offline');
      CloseWebSocket();
      online = false;
    });
    window.addEventListener('online', () => {
      WSDefaultRoute()
      console.log('Atualmente Online');
      SetupWebSocket();
      online = true;
    });
    jaDefinido = true;
  }
}
