<template>
  <v-container fluid
    v-bind="$attrs"
    v-on="$listeners"
    tag="section"
  >
    <slot />
  </v-container>
</template>

<script>
export default {
  name: 'CoreView',
};
</script>
