export const ROUTE_HOME = {
  path: '/home',
  name: 'home',
};

export const ROUTE_ABOUT = {
  path: '/about',
  name: 'about',
};

export const ROUTE_CADASTRO = {
  path: '/abastecimento/cadastro',
  name: 'cadastro',
};

export const ROUTE_SIGN_IN = {
  path: '/sing-in',
  name: 'sign-in',
};

export const ROUTE_ABASTECIMENTO = {
  path: '/abastecimento',
  name: 'abastecimento',
};

// Filtros =============

export const ROUTE_FROTA = {
  path: '/abastecimento/cadastro/frota',
  name: 'frota',
};

export const ROUTE_OPERADOR = {
  path: '/abastecimento/cadastro/operador',
  name: 'operador',
};

export const ROUTE_ORIGEM = {
  path: '/abastecimento/cadastro/origem',
  name: 'origem',
};

export const ROUTE_PROPRIEDADE = {
  path: '/abastecimento/cadastro/propriedade',
  name: 'propriedade',
};

export const ROUTE_ATIVIDADE = {
  path: '/abastecimento/cadastro/atividade',
  name: 'atividade',
};

export const ROUTE_READONLY = {
  path: '/abastecimento/visualizar',
  name: 'readonly',
};
