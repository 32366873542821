<template>
  <v-dialog
      v-model="dialog"
      width="500"
      :persistent='persistent'
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="buttonIcon"
          dark
          v-bind="attrs"
          v-on="on"
          :color="color"
          :class="{'disable-events': disabled, 'mainbtn': true}"
        >
          <v-icon >
            {{buttonIcon}}
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          dark
          v-bind="attrs"
          v-on="on"
          :color="color"
          :style="btnstyle"
          :class="{'disable-events': disabled, 'mainbtn': true}"
        >
          {{label}}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{titulo}}
          <v-icon v-if="icon" class="icon">
            {{icon}}
          </v-icon>
        </v-card-title>
       <br/>
        <v-card-text>
          <slot name="conteudo">
          </slot>
        </v-card-text>

        <v-divider></v-divider>
        <v-spacer></v-spacer>
        <v-card-actions>
          <div class="flex">
            <div v-if="!salvar">
            </div>
            <v-btn
              color="primary"
              text
              @click="$_emitter('sair'), dialog = false"
            >
              {{sair}}
            </v-btn>
            <v-btn
              v-if="salvar"
              color="primary"
              text
              @click="$_emitter('salvar'); dialog = false"
            >
              {{salvar}}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>

export default {
  name: 'CoreDialogBtn',

  props: {
    label: String,
    titulo: String,
    salvar: String,
    // Se preenchido, adiciona um botao de salvar a ESQUERDA, empurra o de sair para DIREITA

    sair: String,
    persistent: Boolean,
    icon: String,
    color: {
      type: String,
      default: 'blue-grey',
    },
    btnstyle: String,
    disabled: Boolean,
    buttonIcon: String,
  },

  data() {
    return {
      dialog: false,
    };
  },

  methods: {
    $_openDialog() {
      this.dialog = false;
    },

    $_emitter(name, valor) {
      if (valor) {
        this.$emit(name, valor);
      } else {
        this.$emit(name);
      }
    },
  },

  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.$emit('onOpen');
        } else {
          this.$emit('onClose');
        }
      },
    },
  },
};
</script>

<style scoped>
 .flex {
   display: flex;
   justify-content: space-between;
 }

 .icon {
   position: absolute;
   right: 5%;
   color: black;
 }

 .disable-events {
  pointer-events: none
}

  .mainbtn {
    box-shadow: 0px 0px 0px;
    transform: translateX(-13px);
  }
</style>
