<template>
  <layout-default-appbar :back-to="rotacadastro" filterable>
    Propriedade
  </layout-default-appbar>
</template>

<script>
import LayoutDefaultAppbar from '@/layouts/default/AppBar.vue';

import { ROUTE_CADASTRO } from '@/constants/ROUTES';

export default {
  name: 'ViewPropriedadeAppBar',

  components: {
    LayoutDefaultAppbar,
  },

  data() {
    return {
      rotacadastro: ROUTE_CADASTRO,
    };
  },
};
</script>
