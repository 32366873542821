<template>
  <layout-default-filters @open="$_OnOpen" @apply="$_applyQueryString"
  :hideButton="filtroInicial">
    <template #superior>
    <div class='div-geral'>
      <div class='primeiro-filtro'>
        <v-form ref="form" >
          <core-date-picker-field clearable
            label="De"
          />

          <core-date-picker-field clearable
            label="Até"
          />
        </v-form >
      </div>
      <div class='segundo-filtro'>
        <div class='radios opacity-switch'>
          <v-radio-group v-model="radioGroup" row>
            <v-radio
              v-for="n in 2"
              :key="n"
              :value="n"
              :label="radioLabel[n - 1]"
            ></v-radio>
          </v-radio-group>
        </div>
        <core-text-field clearable class='txt-filter'
          v-model="nomepessoa"
          :label="radioLabel[radioGroup - 1]"
          :type='inputType'
        />
        <div class='btns-wrap'>
          <v-btn rounded color='primary' class='btns opacity-switch' @click='$_load(0)'>
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>
          <v-btn rounded color='primary' class='btns opacity-switch reducesize' @click='$_load(1)'>
            <v-icon>mdi-arrow-left-thick</v-icon><v-icon>mdi-arrow-right-thick</v-icon>
          </v-btn>
          <v-btn rounded color='primary' class='btns-mini opacity-switch' @click='$_load(2)'>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <template>
        <v-divider></v-divider>
          <div
            class="mx-auto cardlist"
            max-width="500"
            v-if='filtroInicial'
          >
            <v-list two-line class='scroll'>
              <v-list-item-group
                active-class="green--text"
                multiple
              >
                <template v-for="(items, index) in items">
                  <v-list-item :key="items.pesidpessoa">
                    <v-card class='cardpessoa' @click="$_onClickCard(items)">
                      {{items.pesidpessoa}} - {{items.pesnome}}
                    </v-card>
                  </v-list-item>

                  <v-divider
                    v-if="index < items.length - 1"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </div>
          <v-divider></v-divider>
        </template>
      </div>
    </div>
    </template>
  </layout-default-filters>
</template>

<script>
import { mapActions } from 'vuex';

import { GENERIC_QUERIES, OPERADOR_QUERIES } from '@/constants/QUERY_FILTERS';
import { getQueryValue, setQueryValue } from '@/services/query-string';
import { OPERADOR_STORAGE, ABASTECIMENTO_STORAGE } from '@/constants/STORAGE';
import { ROUTE_CADASTRO } from '@/constants/ROUTES';

import LayoutDefaultFilters from '@/layouts/default/NewFilters.vue';

import CoreDatePickerField from '@/components/core/date-picker-field/Index.vue';

import CoreTextField from '@/components/core/text-field/Index.vue';

import errorHandler from '@/utils/error-handler';

import db from '@/plugins/dexie';

import storage from '@/services/storage';

function getSessionStorage(key, defaultValue) {
  return sessionStorage.getItem(key) ?? defaultValue;
}

export default {
  name: 'ViewsHomeFilters',

  components: {
    LayoutDefaultFilters,
    CoreDatePickerField,
    CoreTextField,
  },

  data() {
    return {
      items: [],
      isValid: false,
      radioGroup: 2,
      radioLabel: ['Código', 'Nome'],
      nomepessoa: null,
      filtroInicial: true,
      inputType: 'text',
      anterior: {
        parametroAnterior: null,
        nomeAnterior: null,
      },

      loading: false,

      filters: {
        codigopessoa: null,
      },

      options: {
        page: getSessionStorage(OPERADOR_STORAGE.page, 1),
        itemsPerPage: 10,
        sortBy: ['dopidpessoa'],
        sortDesc: [true],
      },
    };
  },

  methods: {
    ...mapActions({
      $_findFinanceiroDistinct: 'documentopagar/findDistinct',
      $_findFinDistinctCodigo: 'documentopagar/findDistinctCodigo',
    }),

    async $_load(parametroLoad) {
      this.loading = true;
      const ant = this.anterior;
      try {
        if ((typeof this.nomepessoa) === 'string') {
          this.filters.codigopessoa = this.nomepessoa.toUpperCase();
        } else {
          this.filters.codigopessoa = this.nomepessoa;
        }

        // Evitar requests repetidos.
        if (parametroLoad === ant.parametroAnterior && this.nomepessoa === ant.nomeAnterior) {
          return;
        }
        this.anterior.parametroAnterior = parametroLoad;
        this.anterior.nomeAnterior = this.nomepessoa;

        if (parametroLoad === 2) {
          this.nomepessoa = '';
          storage.setItem(OPERADOR_STORAGE.textofiltro, '');
          this.filters.codigopessoa = 'null';
          return;
        }

        if (this.nomepessoa) {
          if (this.radioGroup === 2) {
            const rgxString = `/${this.nomepessoa}/`;
            console.log(rgxString);
            let regex = null;
            if (parametroLoad === 0) {
              regex = new RegExp(`^${this.nomepessoa}.*?$`, 'gi');
            } else {
              regex = new RegExp(`^.*${this.nomepessoa}.*?$`, 'gi');
            }
            await db.pessoa.filter((x) =>
              // eslint-disable-next-line implicit-arrow-linebreak
              regex.test(x.pesnome)).toArray().then((r) => {
              this.items = r;
              this.loading = false;
            });
          } else {
            const regex = new RegExp(this.nomepessoa, 'gi');
            await db.pessoa.filter((x) =>
              // eslint-disable-next-line implicit-arrow-linebreak
              regex.test(x.pesidpessoa)).toArray().then((r) => {
              this.items = r;
              this.loading = false;
            });
          }
        } else {
          // this.items = null;
        }
        return;
      } catch (err) {
        errorHandler.call(this, err);
      } finally {
        this.loading = false;
      }
    },

    $_applyQueryString() {
      setQueryValue(GENERIC_QUERIES.page, 1);
      setQueryValue(OPERADOR_QUERIES.codigopessoa, this.filters.codigopessoa);
    },

    $_OnOpen() {
      setTimeout(() => {
        this.subirForm(false);
      }, 0);
      setTimeout(() => {
        this.subirForm(true);
      }, 0);
    },

    subirForm(bool) {
      const opacity = document.querySelectorAll('.opacity-switch');
      if (bool) {
        if (this.filtroInicial) { return; }
        document.querySelector('.primeiro-filtro').style.opacity = '0';
        document.querySelector('.primeiro-filtro').style.zIndex = '-1';
        document.querySelector('.segundo-filtro').style.top = '-210px';
        for (let i = 0; i < opacity.length; i += 1) {
          opacity[i].style.opacity = '1';
        }

        this.filtroInicial = true;
      } else {
        if (!this.filtroInicial) { return; }
        document.querySelector('.primeiro-filtro').style.opacity = '1';
        document.querySelector('.primeiro-filtro').style.zIndex = '2';
        document.querySelector('.segundo-filtro').style.top = '-49px';
        for (let i = 0; i < opacity.length; i += 1) {
          opacity[i].style.opacity = '0';
        }
        this.filtroInicial = false;
      }
    },

    $_onClickCard(item) {
      const tipo = getSessionStorage(OPERADOR_STORAGE.tipo);
      if (Number(tipo) === 1) {
        sessionStorage.setItem(ABASTECIMENTO_STORAGE.operadora, item.pesnome);
      } else {
        sessionStorage.setItem(ABASTECIMENTO_STORAGE.operadorb, item.pesnome);
      }
      this.$router.replace({
        ...this.$route,
        name: ROUTE_CADASTRO.name,
      });
    },
  },

  watch: {
    filters: {
      deep: true,
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.$refs.form.validate('.segundo-filtro');
        });
      },
    },
    radioGroup: {
      handler() {
        this.nomepessoa = '';
        if (this.radioGroup === 1) {
          this.inputType = 'tel';
          document.querySelector('.reducesize').style.opacity = '0.5';
        } else {
          this.inputType = 'text';
          document.querySelector('.reducesize').style.opacity = '1';
        }
        this.$_load();
      },
    },

    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.filters.codigopessoa = getQueryValue(
          OPERADOR_QUERIES.codigopessoa,
          this.filters.codigopessoa,
        );

        const textofiltro = storage.getItem(OPERADOR_STORAGE.textofiltro);
        textofiltro.then((result) => { this.nomepessoa = result; });
      },
    },
  },
};
</script>

<style scoped>

  .div-geral {
    height: 0%;
  }

  .btns-wrap {
    display: flex;
    justify-content: space-around;
    height: 50px;
  }

  .opacity-switch {
    transition: opacity 0.3s;
  }

  .btns {
    width: 33%;
    transition: opacity 0.3s;
    opacity: 0;
  }

  .btns-mini {
    width: 10%;
    transition: opacity 0.3s;
    opacity: 0;
  }

  .primeiro-filtro {
    transition: opacity 0.3s;
    position: relative;
    opacity: 1;
    z-index: 1;
  }

  .segundo-filtro {
    transition: top 0.4s;
    position: relative;
    z-index: 1;
    height: 0px;
  }

  .radios {
    transition: opacity 0.3s;
    opacity: 0;
  }

  .mx-auto.cardlist {
    transition: opacity 0.3s;
    transition: top 0.4s;
    height: 50vh;
    overflow-y: scroll;
    background-color: rgb(245, 245, 245);
  }

  .cardpessoa {
    height: 80px;
    width: 100%;
    margin-top: 5px;
  }

  /deep/ .txt-filter {
   height: 50px;
  }

</style>
