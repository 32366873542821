<template>
  <layout-default-dialog-appbar :back-to="rotaabastecimento" withDialog>
    Cadastro Abastecimento
  </layout-default-dialog-appbar>
</template>

<script>
import LayoutDefaultDialogAppbar from '@/layouts/default/DialogAppBar.vue';

import { ROUTE_ABASTECIMENTO } from '@/constants/ROUTES';

export default {
  name: 'ViewFrotaAppBar',

  components: {
    LayoutDefaultDialogAppbar,
  },

  data() {
    return {
      rotaabastecimento: ROUTE_ABASTECIMENTO,
    };
  },
};
</script>
