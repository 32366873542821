<template>
  <div class="core-infinite-scroll-item">
    <slot :item="source" :index="index" />
  </div>
</template>

<script>
export default {
  name: 'CoreinifiteScrollItem',

  props: {
    index: Number,
    source: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
