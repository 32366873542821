<template>
  <app-card-operador-skeleton v-bind="$attrs" v-on="$listeners" :actions="actions"
  @click="$_selecionaOrigem(value)" v-if="!loading">
    <template #contrato >
      <div class="superior">
        <div>
          {{ value.lmeidlocalmovimentacaoestoque }}
        </div>
       <div v-if="value.lmedescricao">
        - {{ value.lmedescricao }}
       </div>
      </div>
     </template>
    <template #detalhe1>
      <div v-if="produto" class="superior">
        <div> {{produto.procodigo}}</div>
        <div>- {{produto.prodescricao}}</div>
      </div>
      <div v-else>
        Produto indefinido
      </div>
    </template>

    <slot />
  </app-card-operador-skeleton>
</template>

<script>
import { ABASTECIMENTO_STORAGE } from '@/constants/STORAGE';
import { ROUTE_CADASTRO } from '@/constants/ROUTES';
// import moment from 'moment-timezone';
import AppCardOperadorSkeleton from './Skeleton.vue';
import db from '@/plugins/dexie';

export default {
  name: 'AppCardOperador',

  components: {
    AppCardOperadorSkeleton,
  },

  props: {
    value: Object,
    actions: Boolean,
  },

  data() {
    return {
      produto: null,
      loading: true,
    }
  },

  methods: {
    // FormataData(date) {
    //   // return moment(date, 'YYYY-MM-DD').format('DD/MM-HH:MM');
    //   return moment.tz(date, 'America/Sao_Paulo').local().format('DD/MM-HH:mm');
    // },

    async $_selecionaOrigem(origem) {
      sessionStorage.setItem(ABASTECIMENTO_STORAGE.origem, JSON.stringify(origem));
      sessionStorage.setItem(ABASTECIMENTO_STORAGE.produto, this.produto.prodescricao);
      this.$router.replace({
        ...this.$route,
        name: ROUTE_CADASTRO.name,
      });
    },

    async $_setupCard() {
      const objWhere = { proidproduto: this.$props.value.lmeidproduto }
      const produtoPromise = db.produto.where(objWhere).first();
      produtoPromise.then((item) => {
        console.log(this.produto);
        this.produto = item;
        setTimeout(() => {
          this.loading = false;
        }, 0)
      })
    },
  },

  created() {
    this.$_setupCard();
  }
};
</script>
/<style scoped>
#principal{
  display: block;
}

.flex-container {
  display: flex;
}

.flex-container > * {
  margin-right: 3px;
}

.superior {
  display: flex;
}
</style>
